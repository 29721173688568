<script setup>
const props = defineProps({
  question: String,
  opened: {
    type: Boolean,
    default: false
  }
});
const isOpened = ref(props.opened);
</script>

<template>
    <div class="border-b border-gray-400 py-4">
        <div class="flex px-4 items-center cursor-pointer select-none" @click="isOpened = !isOpened">
            <div class="grow font-normal">{{ question }}</div>
            <div class="pl-4">
              <IconChevronDown :class="[{'-rotate-180':isOpened}]" />
            </div>
        </div>
        <div v-show="isOpened" class="font-extralight px-8 pt-4">
            <slot />
        </div>
    </div>
</template>
